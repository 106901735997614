.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%; /* Full height */
    width: 100%;
}

.logo {
    display: flex;
    flex-direction: column;
}

.app-logo {
    height: 30px;
    border-radius: 50%;
}

.app-logo-blur {
    height: 30px;
    border-radius: 50%;
    opacity: 0.4;
}

.anim-overlay {
    position: absolute;
}

.app-logo:hover {
    /*background-color: var(--bg-button-hover-color);*/
    /*background-color: rgba(0, 0, 0, 0.1); !* Light hover effect *!*/
    transform: scale(1.1); /* Slight zoom effect on hover */

    /*background-color: var(--bg-button-hover-color);*/
    /*border-radius: 50%;*/
    /*border-radius: 4px;*/
}


.top {
    display: flex;
    margin-top: 10px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    cursor: pointer;
}

.bottom {
    /*background-color: red;*/
    display: flex;
    margin-bottom: 10px;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
    /*width: 100%;*/
}

@media only screen and (max-width: 768px) {
    .container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 100%; /* Full height */
        width: 100%;
        flex-wrap: wrap;
    }

    .top {
        display: flex;
        margin-top: unset;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
    }

    .bottom {
        /*background-color: red;*/
        display: flex;
        margin-bottom: unset;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 10px;
        /*width: 100%;*/
    }
}

.icon {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 20px; /* Adjust the size as needed */
    height: 20px; /* Adjust the size as needed */
    cursor: pointer;
    border-radius: 50%;
    transition: background-color 0.3s ease, transform 0.2s ease;
    background-color: transparent;
    padding: 8px;
}

.icon img, .icon svg {
    width: 100%; /* Ensures the icon takes up the full width of the container */
    height: 100%; /* Ensures the icon takes up the full height of the container */
    object-fit: contain;
}

.icon:hover {
    background-color: var(--bg-button-hover-color);
    /*background-color: rgba(0, 0, 0, 0.1); !* Light hover effect *!*/
    transform: scale(1.1); /* Slight zoom effect on hover */
    /*border-radius: 4px;*/
    border-radius: 50%;
}

.icon:active {
    transform: scale(0.95); /* Slight shrink effect when clicked */
}

.icon .current-usage-count-container {
    position: relative;
}

.current-usage-count {
    position: absolute;
    top: 22px;
    right: -10px;
    /*background-color: red;*/
    /*color: white;*/
    background-color: transparent;
    color: var(--main-color);
    font-family: var(--fonts-mono);
    border-radius: 50%;
    padding: 5px;
    font-size: 10px;
    min-width: 20px;
    text-align: center;
    width: 100%;
}

.current-cost-estimate-count {
    position: absolute;
    top: 22px;
    right: -10px;
    /*background-color: red;*/
    /*color: white;*/
    background-color: transparent;
    color: var(--color-estimate-value);
    font-family: var(--fonts-mono);
    border-radius: 50%;
    padding: 5px;
    font-size: 10px;
    min-width: 20px;
    text-align: center;
    width: 100%;
}

/*.icon {*/
/*    cursor: pointer;*/
/*    padding: 4px;*/
/*    !*height: 16px;*!*/
/*    !*width: 16px;*!*/
/*}*/

/*.icon img {*/
/*    height: 20px;*/
/*    width: 20px;*/
/*}*/

/*.icon:hover {*/
/*    background-color: var(--bg-button-hover-color);*/
/*    border-radius: 2px;*/
/*}*/

/*.placeholder {*/
/*    height: 18px;*/
/*    width: 18px;*/
/*    border: dashed 1px var(--darker-secondary-color);*/
/*    border-radius: 2px;*/
/*}*/

@media only screen and (max-width: 768px) {
}

.current-usage-count-container {
    position: relative;
    height: 30px;
}

/*.current-usage-count {*/
/*    font-size: 0.65rem;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    position: absolute;*/
/*    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;*/
/*    top: 23px;*/
/*    !*color: var(--main-color-alternative);*!*/
/*}*/

.help-menu-container {
    margin: 10px 4px;
    /*padding-top: 24px;*/
}

.more-menu-container {
    margin: 10px 4px;
    padding-top: 24px;
}

/*.suggestion-modal-container {*/
/*    !*width: 400px;*!*/
/*    padding: 20px;*/
/*    !*background-color: #fff;*!*/
/*    border-radius: 8px;*/
/*    !*box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);*!*/
/*    font-family: Arial, sans-serif;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    gap: 10px;*/
/*    box-sizing: border-box;*/
/*    height: 100%;*/
/*}*/

/*@media only screen and (min-width: 768px) {*/
/*    .suggestion-modal-container {*/
/*        min-height: 60vh;*/
/*        min-width: 70vh;*/
/*    }*/
/*}*/
/*!* Title of the modal *!*/
/*.suggestion-modal-container h1 {*/
/*    font-size: 24px;*/
/*    margin-bottom: 20px;*/
/*    !*color: #333;*!*/
/*}*/

/*!* Row container for each input section *!*/
/*.suggestion-modal-row {*/
/*    display: flex;*/
/*    !*align-items: center;*!*/
/*    !*margin-bottom: 15px;*!*/
/*    gap: 10px;*/
/*}*/

/*.suggestion-modal-row-main {*/
/*    flex: 5;*/
/*    height: 100%;*/
/*}*/



/*!* Title for each input section *!*/
/*.suggestion-modal-title {*/
/*    flex: 1;*/
/*    font-weight: bold;*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: flex-start;*/
/*    align-items: center;*/
/*    gap: 10px;*/
/*    !*font-size: 16px;*!*/
/*    !*color: #555;*!*/
/*}*/

/*!* Input container *!*/
/*.suggestion-modal-input {*/
/*    flex: 2;*/
/*}*/

/*!* Select input styling *!*/
/*.suggestion-modal-input select {*/
/*    width: 100%;*/
/*    padding: 8px;*/
/*    !*font-size: 14px;*!*/
/*    border: 1px solid var(--border-color);*/
/*    !*border-radius: 4px;*!*/
/*}*/

/*.suggestion-modal-attachment {*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    flex-wrap: wrap;*/
/*}*/

/*!* Textarea styling *!*/
/*.suggestion-modal-input textarea {*/
/*    !*width: 400px;*!*/
/*    !*height: 256px;*!*/
/*    padding: 4px;*/
/*    box-sizing: border-box;*/
/*    !*font-size: 14px;*!*/
/*    border: 1px solid var(--border-color);*/
/*    border-radius: 4px;*/
/*    !*resize: vertical;*!*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    min-height: 100px;*/
/*}*/

.image-in-thread {
    /*border-radius: 16px;*/
    height: 48px;
    max-width: 100%;
}

.attachment-container {
    border: dotted 1px var(--border-color);
    display: flex;
    flex-direction: column;
}

.suggestion-modal-panel {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.row-panel {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}
