.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}
.header-line {
    border-bottom: solid 1px var(--border-color);
}

.header-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.header-buttons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-right: 10px;
}




.container {
    overflow-y: auto;
    margin: 10px 40px;
}

.chat-conversation-top {
    /*background-color: var(--bg-secondary-color);*/
    display: flex;
    flex-direction: column;
    position: relative;
}

.chat-conversation-top > div  {
    margin: 2px 0; /* This reduces the space between paragraphs */
    padding: 10px;
    border-radius: 4px; /* This adds rounded corners */
    white-space: pre-wrap;
    word-break: break-word;
    /*width: 80%;*/
}

.message-entry-line {
    display: flex;
    flex-direction: row;
}

.message-entry-message {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    gap: 8px;
}


.icon-role {
    position: absolute;
    display: flex;
    flex-direction: column;
    /*top: 0px;*/
    /*left: 0px;*/
}

.icon-role img {
    height: 20px;
    width: 20px;
}


.assistant {
    color: var(--lighter-secondary-color);
}

.user {
    background-color: var(--bg-chat-user); /* Light pinkish-red color */
}

.system {
    background-color: var(--bg-chat-system); /* Light pinkish-red color */
    color: var(--chat-user-color);
}

.message-footer {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.image-in-thread {
    border-radius: 16px;
    max-height: 160px;
    max-width: 100%;
}

.role {
    font-weight: bold;
}

.published {
    margin: 10px;
    /*font-size: larger;*/
    color: var(--darker-secondary-color);
    font-weight: bold;
}

.error {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
}

.markdown-parent {
    white-space: normal !important;
}

.model-name-sub {
    font-family: var(--fonts-mono);
    /*font-size: 0.8rem;*/
    /*width: 40px;*/
    /*word-wrap: break-word;*/
    /*white-space: break-spaces;*/
    /*opacity: 0.8;*/
    /*z-index: 100;*/
    color: var(--middle-darker-secondary-color);
    /*border: solid 1px var(--border-color);*/
    /*padding: 4px 0;*/
    /*border-radius: 8px;*/
}

@media (max-width: 768px) {
    .icon-role {
        position: unset;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        /*display: none;*/
    }
    .message-entry-message {
        margin-left: unset;
    }
    .model-name-sub {
        /*font-family: var(--fonts-mono);*/
        /*font-size: var(--font-size-small);*/
        width: unset;
    }
}

